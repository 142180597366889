import React from 'react';
import { Form as ReduxForm } from 'redux-form';

import Form from '../../../elements/Form';
import { isRequired } from '../../../core/utils/validators';
import { mapToOptions } from '../../../core/utils/helpers';

const { PRODUCT_STATUS_OPTIONS, PRODUCT_SETTING_OPTIONS } = require('../../../core/utils/options').default;

const FormItem = Form.Item;
const FormSection = Form.Section;
const formTypes = Form.formTypes;

export default function () {
    // const { productId } = this.props;
    const categoryOptions = mapToOptions(this.props.productCategories);
    const supplierOptions = mapToOptions(this.props.suppliers.filter(item => item.is_supplier === 1));
    const manufacturerOptions = mapToOptions(this.props.suppliers.filter(item => item.is_manufacturer === 1));
    const unitsFormItem = (
        <div>
            <div className="ant-form-item-label">
                <label htmlFor="unit" title="Unit of Measure">Unit of Measure</label>
            </div>
            <div className="flex-center">
                <FormItem type={formTypes.NUMBER} name="unit_measure" max={Infinity} label={false} className="mr-1 w-50" placeholder="Measure" />
                <FormItem
                    type={formTypes.TEXT}
                    name="unit"
                    label={false}
                    className="w-25"
                    placeholder="Units" />
            </div>
        </div>
    );
    const formConfig = [
        { type: formTypes.TEXT, name: 'title', label: 'Item Title', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'sku', label: 'Product Code', validate: [isRequired] },
        { type: formTypes.TEXT, name: 'drug_tariff_location', label: 'Drug Tariff Location' },
        { type: formTypes.TEXT, name: 'pip_code', label: 'PIP Code', validate: [isRequired] },
        { type: formTypes.SELECT, name: 'product_category_id', label: 'Category', options: categoryOptions, validate: [isRequired] },
        { type: formTypes.SELECT, name: 'status', label: 'Status', options: PRODUCT_STATUS_OPTIONS },
        { type: formTypes.CUSTOM, name: unitsFormItem },
        { type: formTypes.TEXT, name: 'price', label: 'List Price', className: 'currency-input', validate: [isRequired] },
        { type: formTypes.SELECT, name: 'supplier_id', label: 'Primary Supplier', options: supplierOptions, validate: [isRequired] },
        { type: formTypes.SELECT, name: 'manufacturer_id', label: 'Manufacturer', options: manufacturerOptions },
        { type: formTypes.TEXTAREA, name: 'description', label: 'Additional Description' },
        { type: formTypes.CHECKBOX, name: 'settings', options: PRODUCT_SETTING_OPTIONS },
        { type: formTypes.CHECKBOX, name: 'requires_prescription', label: 'Requires Prescription' },
    ];

    return (
        <ReduxForm onSubmit={this.props.handleSubmit(this.handleUpdateProductSubmit)} className="ant-form ant-form-vertical">
            <FormSection loading={this.props.isFetching} config={formConfig} />
        </ReduxForm>
    );
}
