import React from 'react';
import { Checkbox, Button, Select, Form as AntForm } from 'antd';

import Icon from '../../../elements/Icon';

const { SUPPLIER_TYPE_OPTIONS } = require('../../../core/utils/options').default;

const AntItem = AntForm.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;

export default function () {
    const { products, changeVisible } = this.props;
    const { productError, typesError } = this.state;

    // Form must be ant form due to select search,
    // as the select component must be called within this render to re render
    // however the redux field component property must be a class or function,
    // this function must be called as `this.function` to keep component focus
    // which means the select component won't be re rendered when fetching the options
    return (
        <AntForm>

            <div className="mb-2">
                <AntItem label="Product Code or Name">
                    <div className={productError ? 'has-error' : ''}>
                        <Select
                            value={this.state.productId}
                            onChange={this.handleChangeProducts}
                            onSearch={this.handleSearchProducts}
                            filterOption={false}
                            showSearch
                            suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}>
                            {products.map(item => <Option key={`select-product-${item.id}`} value={item.id}>#{item.id} - {item.description}</Option>)}
                        </Select>
                        {productError ? <span className="ant-form-explain">Required</span> : false}
                    </div>
                </AntItem>
            </div>

            <div className="text-center">
                <AntItem label={false}>
                    <div className={typesError ? 'has-error' : ''}>
                        <CheckboxGroup
                            value={this.state.supplierTypes}
                            onChange={this.handleChangeSupplierTypes}
                            options={SUPPLIER_TYPE_OPTIONS} />
                        {typesError ? <span className="ant-form-explain">At least one is required</span> : false}
                    </div>
                </AntItem>
            </div>

            <div className="text-center mt-2">
                <Button type="primary" className="wide" onClick={this.handleAddProductSupplierSubmit}>Add to manufacturer</Button>
                <div>
                    <Button className="link-green btn-empty underlined mt-1 mb-1" onClick={() => changeVisible(false)}>
                        cancel
                    </Button>
                </div>
            </div>
        </AntForm>
    );
}
