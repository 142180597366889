import React from 'react';
import { Button, Select, Form as AntForm } from 'antd';

import Icon from '../../../elements/Icon';

const AntItem = AntForm.Item;
const Option = Select.Option;

export default function () {
    const { nurses, carehomes, changeVisible } = this.props;
    const { addToNurse, error } = this.state;

    // Get option components
    let options = [];
    if (addToNurse) {
        options = carehomes.map(item => <Option key={`select-carehome-${item.id}`} value={item.id}>#{item.id} - {item.title}</Option>);
    } else {
        options = nurses.map(item => <Option key={`select-nurse-${item.id}`} value={item.id}>#{item.id} - {item.first_name} {item.last_name}</Option>);
    }

    // Set value if carehome or nurse is selected
    let value;
    if (this.state.selectedNurseId) {
        value = this.state.selectedNurseId;
    }
    if (this.state.selectedCarehomeId) {
        value = this.state.selectedCarehomeId;
    }

    // Form must be ant form due to select search,
    // as the select component must be called within this render to re render
    // however the redux field component property must be a class or function,
    // this function must be called as `this.function` to keep component focus
    // which means the select component won't be re rendered when fetching the options
    return (
        <AntForm>

            <div className="mb-2">
                <AntItem label={`Name or ID of ${addToNurse ? 'carehome' : 'nurse'}`}>
                    <div className={error ? 'has-error' : ''}>
                        <Select
                            value={value}
                            onChange={this.handleChangeId}
                            onSearch={this.handleSearch}
                            filterOption={false}
                            showSearch
                            placeholder={'Type three or more characters to search'}
                            suffixIcon={<Icon name="arrow-down" className="anticon anticon-down ant-select-arrow-icon" />}>
                            {options}
                        </Select>
                        {error ? <span className="ant-form-explain">Required</span> : false}
                    </div>
                </AntItem>
            </div>

            <div className="text-center mt-2">
                <Button type="primary" className="wide" onClick={this.handleAddCarehomeNurseSubmit}>{`Add to ${addToNurse ? 'nurse' : 'carehome'}`}</Button>
                <div>
                    <Button className="link-green btn-empty underlined mt-1 mb-1" onClick={() => changeVisible(false)}>
                        cancel
                    </Button>
                </div>
            </div>
        </AntForm>
    );
}
