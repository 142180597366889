import React from 'react';
import { Button } from 'antd';

import Gridview from '../../../elements/Gridview';
import AddCareHome from '../../modals/AddCareHome';
import { displayId, GridButton } from '../../../core/utils/helpers';

export default function () {
    // Render gridview buttons
    const renderButtons = (text, record) => (
        <div className="btn-bar">
            <GridButton href={`/care-home/${record.id}`} />
            <GridButton href={() => this.handleRemoveCarehomeNurse(record.id)} title="Remove" />
        </div>
    );

    // Gridview config
    const config = [
        { dataIndex: 'id', title: 'ID', width: 80, render: displayId },
        { dataIndex: 'title',
            title: 'Care Name',
            render: (text, record) => <div className="text-ellipsis">{record.title}</div>,
            width: 180,
        },
        { dataIndex: 'key', align: 'right', render: renderButtons },
    ];

    // Gridview title
    const title = (
        <div className="table__topnav">
            <h6 className="h-6">Belongs to care homes</h6>
            <Button type="default" className="ant-btn-xs grey" onClick={() => { this.setModalAddHomeVisible(true); }}>Assign home to nurse</Button>
        </div>
    );

    return (
        <div>
            <Gridview
                scroll={{ y: 250 }}
                preHeader={title}
                config={config}
                data={this.props.carehomes}
                pagination={false} />

            <AddCareHome nurseId={this.props.nurseId} visible={this.state.modalAddHomeVisible} changeVisible={this.setModalAddHomeVisible} />
        </div>
    );
}
