import { isEmpty } from 'underscore';

const { STATUS_INACTIVE, ORDER_STATUS_SCRIPT_SUPPLIED } = require('./values').default;

// Validate required
const isRequired = value => (value ? undefined : 'Required');

// Validate required and 0 is allowed
const isRequiredOrZero = value => (value || value === 0 ? undefined : 'Required');

// Validate array value must have at least one child value
const isOneOfManyRequired = value => (!isEmpty(value) ? undefined : 'At least one is required');

// Validate is string
const isString = value => (typeof value === 'string' ? undefined : 'Must be a string');

// Validate is number
const isNumber = value => (!isNaN(parseInt(value, 10)) ? undefined : 'Must be a number');

// Validate is character length is less than number
const charLessThan = (value, condition) => {
    if (value) {
        return value.length < condition ? undefined : `Must be less than ${condition} characters`;
    }
    return undefined;
};

// ----- Specific validators ----- //

/**
 * Validate order form
 * @param  {object} values
 * @return {object} - object containing errors for problem field
 */
const validateOrder = (values) => {
    const errors = {};
    if (values.auto_order) {
        if (!values.interval) {
            errors.interval = 'Required';
        }
    }

    if (values.order_status === ORDER_STATUS_SCRIPT_SUPPLIED) {
        if (!values.funding_method) {
            errors.funding_method = 'Required';
        }
    }

    return errors;
};

/**
 * Validate customer form
 * @param  {object} values
 * @return {object} - object containing errors for problem field
 */
const validateCustomer = (values) => {
    const errors = {};
    // if (values.is_active === STATUS_INACTIVE) {
    //     if (!values.deactivation_reason) {
    //         errors.deactivation_reason = 'Required';
    //     }
    // }

    return errors;
};

const isEmail = value =>
    (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
        ? 'Invalid email address'
        : undefined);

export {
    isRequired,
    isRequiredOrZero,
    isOneOfManyRequired,
    isString,
    isNumber,
    charLessThan,
    isEmail,
    validateOrder,
    validateCustomer,
};
