import React from 'react';
import { Form as AntForm, Button, Upload, Row, Col } from 'antd';

import { getApiUrl, getAuthHeaders } from '../../../core/utils/api';

function UploadProductStock() {
    const {
        handleSampleDownload,
        isFetching,
        handleSubmit,
        handleBeforeUpload,
        handleUploadChange,
        fileName,
        fileList = []
    } = this.props || {};

    return (
        <div className="">
            <div className="text-center">
                <h4 className="h-4-regular mb-0">Upload stock refresh CSV</h4>
                <a className="section__label underlined" onClick={handleSampleDownload}>
                    {isFetching ? 'Downloading...' : 'download sample file'}
                </a>
            </div>
            <Row type="flex" justify="center">
                <Col span={24} md={22}>
                    <AntForm onSubmit={handleSubmit} layout="vertical">
                        <div className="mt-3 flex-center justify-content-center text-center">
                            <Upload
                                accept=".csv,.xlsx"
                                action={`${getApiUrl()}/product/upload`}
                                beforeUpload={handleBeforeUpload}
                                data={{ filename: fileName }}
                                fileList={fileList}
                                headers={getAuthHeaders()}
                                name="file"
                                onChange={handleUploadChange}>
                                <Button type="default" className="medium">Upload</Button>
                            </Upload>
                        </div>
                        <div className="text-center mt-2">
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="extrawide"
                                disabled={fileList.length === 0}>
                                Upload and refresh stock levels
                            </Button>
                        </div>
                    </AntForm>
                </Col>
            </Row>
        </div>
    );
}

export default UploadProductStock;
